<template>
    <div class="card-ticket">
        <div class="row">
            <div class="xs12">
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-list-status"></i>
                    </div>
                    <div :class="textRight" :style="`color:${item.maMauTrangThai}`">
                        {{ item.trangThai }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{ $Helper.Common.formatDateTime(item.gioXuatBen) }}
                    </div>
                </div>
                <div :class="rowText" class="text-link" @click="XemBanTheHien(item)">
                    <div class="mr-2">
                        <i class="mdi mdi-script-text-outline"></i>
                    </div>
                    <div :class="textRight">{{ item.maLenh }}</div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-card-bulleted-outline"></i>
                    </div>
                    <div :class="textRight">{{ item.bienKiemSoat }}</div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-steering"></i>
                    </div>
                    <!-- <div :class="textRight">{{ item.LaiXe }}</div> -->
                    <div :class="textRight" v-if="item.danhSachLaiXe">
                        <span
                            :class="`${index == 0 ? ' color-primary' : ''}`"
                            v-for="(laiXe, index) in item.danhSachLaiXe"
                            :key="index"
                        >
                            {{
                                index == 0 ? laiXe.tenLaiXe : " - " + laiXe.tenLaiXe
                            }}</span
                        >
                    </div>
                </div>

                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-road-variant"></i>
                    </div>
                    <div :class="textRight">
                        {{ `${item.benDi} - ${item.benDen} (${item.maTuyen})` }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="xs12">
                <DxButton
                    text="Xem chi tiết"
                    type="default"
                    styling-mode="outlined"
                    icon="mdi mdi-eye"
                    width="100%"
                    @click="XemChiTietLenh()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    computed: {
        ChiTietLenh: {
            get() {
                return this.$store.state.TraCuuLenh.ChiTietLenh;
            },
            set(data) {
                this.$store.commit("TraCuuLenh/Set", {
                    key: "ChiTietLenh",
                    data: data,
                });
            },
        },
    },
    methods: {
        XemBanTheHien(item) {
            this.$emit("XemBanTheHien", item);
        },
        XemChiTietLenh() {
            this.ChiTietLenh = this.item;
            this.$router.push("/Chi-Tiet-Lenh");
        },
    },
    created() {},
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    font-size: 14px;
    word-break: break-word;
    white-space: break-spaces;
}
</style>
