<template>
    <div
        :class="screenWidth > 768 ? `pa-4` : 'pa-2'"
        style="margin: 0 auto"
        :style="screenWidth > 768 ? `width: 888px` : `100%`"
    >
        <div class="row align-center">
            <span class="font-16 font-medium"> Tra cứu theo:</span>
            <span
                class="color-primary font-medium mx-2"
                v-if="!ThongTinTraCuu.OptionTraCuu && screenWidth > 768"
            >
                QR Code
            </span>
            <div style="position: relative" class="widthinput ml-2">
                <DxRadioGroup
                    :items="DanhSachKieuTraCuu"
                    v-model:value="ThongTinTraCuu.OptionTraCuu"
                    displayExpr="text"
                    valueExpr="value"
                    layout="horizontal"
                    :onValueChanged="onValueChanged"
                />
            </div>
        </div>
        <DxValidationGroup ref="formValidation">
            <div class="row align-center" v-if="screenWidth > 768">
                <div class="grow" v-if="ThongTinTraCuu.OptionTraCuu">
                    <div class="row">
                        <div class="xs4 mr-3">
                            <DxTextBox
                                v-model="ThongTinTraCuu.MaSoThue"
                                label="Mã số thuế (*)"
                                labelMode="floating"
                                styling-mode="outlined"
                                validationMessageMode="always"
                                :height="screenWidth > 768 ? 48 : 36"
                            >
                                <DxValidator>
                                    <DxRequiredRule message="Không được bỏ trống!" />
                                    <DxStringLengthRule
                                        :max="16"
                                        message="Không được vượt quá 16 ký tự!"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                        <div class="xs8 ml-2">
                            <div
                                class="row"
                                v-if="ThongTinTraCuu.OptionTraCuu == 'BienKiemSoat'"
                            >
                                <div class="xs6 mr-3">
                                    <DxTextBox
                                        v-model="ThongTinTraCuu.BienKiemSoat"
                                        label="Biển kiểm soát (*)"
                                        labelMode="floating"
                                        styling-mode="outlined"
                                        validationMessageMode="always"
                                        :height="screenWidth > 768 ? 48 : 36"
                                    >
                                        <DxValidator>
                                            <DxRequiredRule
                                                message="Không được bỏ trống!"
                                            />
                                            <DxStringLengthRule
                                                :max="16"
                                                message="Không được vượt quá 16 ký tự!"
                                            />
                                        </DxValidator>
                                    </DxTextBox>
                                </div>
                                <div class="xs6 ml-2">
                                    <DxDateBox
                                        v-model="ThongTinTraCuu.NgayKhoiHanh"
                                        displayFormat="dd/MM/yyyy"
                                        type="date"
                                        dropDownButtonTemplate="customIcon"
                                        validationMessageMode="always"
                                        :useMaskBehavior="true"
                                        stylingMode="outlined"
                                        label="Ngày tra cứu (*)"
                                        labelMode="floating"
                                        :height="screenWidth > 768 ? 48 : 36"
                                    >
                                        <DxValidator>
                                            <DxRequiredRule
                                                message="Không được bỏ trống!"
                                            />
                                            <DxRangeRule
                                                :max="MaxDate"
                                                :message="
                                                    $i18n.t('DefaultString.MaxDate')
                                                "
                                            />
                                            <DxRangeRule
                                                :min="MinDate"
                                                :message="
                                                    $i18n.t('DefaultString.MinDate')
                                                "
                                            />
                                        </DxValidator>
                                        <template #customIcon="{}">
                                            <DxButton icon="mdi mdi-calendar" />
                                        </template>
                                    </DxDateBox>
                                </div>
                            </div>

                            <DxTextBox
                                v-else-if="ThongTinTraCuu.OptionTraCuu == 'MaLenh'"
                                v-model="ThongTinTraCuu.MaLenh"
                                label="Mã lệnh (*)"
                                labelMode="floating"
                                styling-mode="outlined"
                                validationMessageMode="always"
                                :height="screenWidth > 768 ? 48 : 36"
                            >
                                <DxValidator>
                                    <DxRequiredRule message="Không được bỏ trống!" />
                                    <DxStringLengthRule
                                        :max="16"
                                        message="Không được vượt quá 16 ký tự!"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>
                </div>
                <DxButton
                    class="ml-3"
                    :icon="$i18n.t('icon.search')"
                    :text="screenWidth > 768 ? `Tra cứu` : ``"
                    :rtlEnabled="true"
                    styling-mode="outlined"
                    :width="144"
                    :height="screenWidth > 768 ? 48 : 36"
                    @click="TraCuu"
                />
            </div>
            <div v-else>
                <div class="row mb-4" v-if="ThongTinTraCuu.OptionTraCuu">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinTraCuu.MaSoThue"
                            label="Mã số thuế (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                            :height="screenWidth > 768 ? 48 : 36"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Không được bỏ trống!" />
                                <DxStringLengthRule
                                    :max="16"
                                    message="Không được vượt quá 16 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <div
                    class="row mb-4"
                    v-if="ThongTinTraCuu.OptionTraCuu == 'BienKiemSoat'"
                >
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinTraCuu.BienKiemSoat"
                            label="Biển kiểm soát (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                            :height="screenWidth > 768 ? 48 : 36"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Không được bỏ trống!" />
                                <DxStringLengthRule
                                    :max="16"
                                    message="Không được vượt quá 16 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <div
                    class="row mb-4"
                    v-if="ThongTinTraCuu.OptionTraCuu == 'BienKiemSoat'"
                >
                    <div class="xs12">
                        <DxDateBox
                            v-model="ThongTinTraCuu.NgayKhoiHanh"
                            displayFormat="dd/MM/yyyy"
                            type="date"
                            pickerType="rollers"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            :useMaskBehavior="true"
                            stylingMode="outlined"
                            label="Ngày tra cứu (*)"
                            labelMode="floating"
                            :height="screenWidth > 768 ? 48 : 36"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Không được bỏ trống!" />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-4" v-if="ThongTinTraCuu.OptionTraCuu == 'MaLenh'">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinTraCuu.MaLenh"
                            label="Mã lệnh (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                            :height="screenWidth > 768 ? 48 : 36"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Không được bỏ trống!" />
                                <DxStringLengthRule
                                    :max="16"
                                    message="Không được vượt quá 16 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div
                    class="row justify-center color-primary font-medium mb-2"
                    v-if="!ThongTinTraCuu.OptionTraCuu"
                >
                    Tra cứu theo QR Code
                </div>
                <div class="row">
                    <div class="xs12">
                        <DxButton
                            :icon="$i18n.t('icon.search')"
                            text="Tra cứu"
                            :rtlEnabled="true"
                            styling-mode="outlined"
                            width="100%"
                            :height="screenWidth > 768 ? 48 : 36"
                            @click="TraCuu"
                        />
                    </div>
                </div>
            </div>
        </DxValidationGroup>
        <!-- <div class="row font-italic font-14 mt-1">
            Kết quả tra cứu chỉ bao gồm lệnh mà xe đang thực hiện
        </div> -->
    </div>
    <div
        class="row font-medium font-16"
        :class="screenWidth > 768 ? `mt-4 px-4` : `mt-3 justify-center`"
    >
        Kết quả tra cứu
    </div>
    <div class="row" :class="screenWidth > 768 ? `px-4` : ``">
        <div class="xs12">
            <DxDataGrid
                v-if="screenWidth > 768"
                class="table-page mt-2"
                :remote-operations="true"
                :show-borders="true"
                :data-source="DanhSachTraCuuLenh"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-row-lines="true"
                :ref="gridRefName"
                :loadPanel="{
                    showIndicator: false,
                    showPane: false,
                    text: '',
                }"
                no-data-text="Không có dữ liệu!"
                key-expr="maLenh"
                height="calc(100vh - 256px)"
                @cellHoverChanged="onCellHoverChanged"
            >
                <!-- :onRowClick="onRowClick" -->
                <DxFilterRow :visible="false" />
                <DxPaging :page-size="50" />
                <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                <DxColumnFixing :enabled="true" />

                <DxColumn
                    data-field="tenDoanhNghiep"
                    caption="Đơn vị"
                    :groupIndex="0"
                ></DxColumn>

                <DxColumn
                    data-field="maLenh"
                    caption="Mã lệnh điện tử"
                    alignment="center"
                    column-min-width="200"
                    cell-template="tempMaLenh"
                />
                <template #tempMaLenh="{ data }">
                    <div
                        class="text-link font-medium"
                        title="Nhấn để xem bản thể hiện của lệnh"
                        @click="XemBanTheHien(data.data)"
                    >
                        {{ data.data.maLenh }}
                    </div>
                </template>
                <DxColumn
                    data-field="bienKiemSoat"
                    caption="Biển kiểm soát"
                    alignment="center"
                    column-min-width="150"
                />
                <DxColumn
                    data-field="LaiXe"
                    caption="Lái xe"
                    alignment="center"
                    column-min-width="150"
                    cellTemplate="tmpLaiXe"
                />
                <template #tmpLaiXe="{ data }">
                    <div class="row">
                        {{ GetTextLaiXe(data.data) }}
                    </div>
                </template>

                <DxColumn
                    :allowHeaderFiltering="false"
                    alignment="left"
                    data-field="hinhThucChay"
                    caption="Loại lệnh"
                ></DxColumn>
                <DxColumn
                    :allowHeaderFiltering="false"
                    alignment="left"
                    data-field="maTuyen"
                    caption="Mã tuyến"
                ></DxColumn>
                <DxColumn
                    :allowHeaderFiltering="false"
                    alignment="left"
                    data-field="gioXuatBen"
                    caption="Thời gian xuất bến"
                    dataType="date"
                    format="HH:mm dd/MM/yyyy"
                ></DxColumn>
                <DxColumn
                    :allowHeaderFiltering="true"
                    :allowFiltering="false"
                    alignment="left"
                    data-field="thuTruongDonViDaKyLenh"
                    caption="Doanh nghiệp ký lệnh"
                    cell-template="doanh-nghiep-ky"
                >
                    <DxHeaderFilter
                        :data-source="headerFilterDataKyLenh('thuTruongDonViDaKyLenh')"
                    />
                </DxColumn>
                <template #doanh-nghiep-ky="{ data }">
                    <div
                        :style="`color:${
                            data.data.thuTruongDonViDaKyLenh ? '#13B000' : '#D10909'
                        }`"
                    >
                        {{ data.data.thuTruongDonViDaKyLenh ? "Đã ký" : "Chưa ký" }}
                    </div>
                </template>
                <DxColumn
                    :allowHeaderFiltering="false"
                    alignment="left"
                    data-field="thoiGianKyLenh"
                    caption="Thời gian ký lệnh"
                    dataType="date"
                    format="HH:mm:ss dd/MM/yyyy"
                ></DxColumn>

                <DxColumn caption="Bến đi" alignment="center">
                    <DxColumn
                        :allowHeaderFiltering="false"
                        alignment="left"
                        data-field="benDi"
                        caption="Tên bến"
                    ></DxColumn>
                    <DxColumn
                        :allowHeaderFiltering="true"
                        :allowFiltering="false"
                        alignment="left"
                        data-field="benDiDaKyLenh"
                        caption="Bến đi ký lệnh"
                        cell-template="ben-di-ky"
                    >
                        <DxHeaderFilter
                            :data-source="headerFilterDataKyLenh('benDiDaKyLenh')"
                        />
                    </DxColumn>
                    <DxColumn
                        :allowHeaderFiltering="false"
                        alignment="left"
                        data-field="thoiGianBenDiKyLenh"
                        caption="Thời gian ký lệnh"
                        dataType="date"
                        format="HH:mm:ss dd/MM/yyyy"
                    ></DxColumn>
                </DxColumn>

                <template #ben-di-ky="{ data }">
                    <div
                        :style="`color:${
                            data.data.benDiDaKyLenh ? '#13B000' : '#D10909'
                        }`"
                    >
                        {{ data.data.benDiDaKyLenh ? "Đã ký" : "Chưa ký" }}
                    </div>
                </template>

                <DxColumn caption="Bến đến" alignment="center">
                    <DxColumn
                        :allowHeaderFiltering="false"
                        alignment="left"
                        data-field="benDen"
                        caption="Tên bến"
                    ></DxColumn>
                    <DxColumn
                        :allowHeaderFiltering="true"
                        :allowFiltering="false"
                        alignment="left"
                        data-field="benDenDaKyLenh"
                        caption="Bến đến ký lệnh"
                        cell-template="ben-den-ky"
                    >
                        <DxHeaderFilter
                            :data-source="headerFilterDataKyLenh('benDenDaKyLenh')"
                        />
                    </DxColumn>
                    <DxColumn
                        :allowHeaderFiltering="false"
                        alignment="left"
                        data-field="thoiGianBenDenKyLenh"
                        caption="Thời gian ký lệnh"
                        dataType="date"
                        format="HH:mm:ss dd/MM/yyyy"
                    ></DxColumn>
                </DxColumn>
                <template #ben-den-ky="{ data }">
                    <div
                        :style="`color:${
                            data.data.benDenDaKyLenh ? '#13B000' : '#D10909'
                        }`"
                    >
                        {{ data.data.benDenDaKyLenh ? "Đã ký" : "Chưa ký" }}
                    </div>
                </template>

                <DxColumn alignment="center" caption="Truyền tải xe buýt">
                    <DxColumn
                        :allowHeaderFiltering="true"
                        :allowFiltering="false"
                        alignment="left"
                        data-field="truyenTai_XeBuyt_ThanhCong"
                        caption="Trạng thái"
                        cell-template="truyen-tai-xe-buyt"
                    >
                        <DxHeaderFilter
                            :data-source="
                                headerFilterDataTruyenTai('truyenTai_XeBuyt_ThanhCong')
                            "
                        />
                    </DxColumn>
                    <DxColumn
                        :allowHeaderFiltering="false"
                        alignment="left"
                        data-field="thoiGianTruyenTaiXeBuyt"
                        caption="Thời gian truyền tải"
                        dataType="date"
                        format="HH:mm:ss dd/MM/yyyy"
                    ></DxColumn>
                </DxColumn>
                <template #truyen-tai-xe-buyt="{ data }">
                    <div
                        :style="`color:${
                            data.data.truyenTai_XeBuyt_ThanhCong == true
                                ? '#13B000'
                                : data.data.truyenTai_XeBuyt_ThanhCong == null
                                ? '#FB8C00'
                                : '#D10909'
                        }`"
                    >
                        {{
                            data.data.truyenTai_XeBuyt_ThanhCong == null
                                ? "Chưa truyền tải"
                                : data.data.truyenTai_XeBuyt_ThanhCong == true
                                ? "Truyền tải thành công"
                                : "Truyền tải gặp lỗi"
                        }}
                    </div>
                </template>

                <DxColumn alignment="center" caption="Truyền tải xuất bến">
                    <DxColumn
                        :allowHeaderFiltering="true"
                        :allowFiltering="false"
                        alignment="left"
                        data-field="truyenTai_XuatBen_ThanhCong"
                        caption="Trạng thái"
                        cell-template="truyen-tai-xuat-ben"
                    >
                        <DxHeaderFilter
                            :data-source="
                                headerFilterDataTruyenTai('truyenTai_XuatBen_ThanhCong')
                            "
                        />
                    </DxColumn>
                    <DxColumn
                        :allowHeaderFiltering="false"
                        alignment="left"
                        data-field="thoiGianTruyenTaiXuatBen"
                        caption="Thời gian truyền tải"
                        dataType="date"
                        format="HH:mm:ss dd/MM/yyyy"
                    ></DxColumn>
                </DxColumn>
                <template #truyen-tai-xuat-ben="{ data }">
                    <div
                        :style="`color:${
                            data.data.truyenTai_XuatBen_ThanhCong == true
                                ? '#13B000'
                                : data.data.truyenTai_XuatBen_ThanhCong == null
                                ? '#FB8C00'
                                : '#D10909'
                        }`"
                    >
                        {{
                            data.data.truyenTai_XuatBen_ThanhCong == null
                                ? "Chưa truyền tải"
                                : data.data.truyenTai_XuatBen_ThanhCong == true
                                ? "Truyền tải thành công"
                                : "Truyền tải gặp lỗi"
                        }}
                    </div>
                </template>

                <DxColumn alignment="center" caption="Truyền tải đến bến">
                    <DxColumn
                        :allowHeaderFiltering="true"
                        :allowFiltering="false"
                        alignment="left"
                        data-field="truyenTai_DenBen_ThanhCong"
                        caption="Trạng thái"
                        cell-template="truyen-tai-den-ben"
                    >
                        <DxHeaderFilter
                            :data-source="
                                headerFilterDataTruyenTai('truyenTai_DenBen_ThanhCong')
                            "
                        />
                    </DxColumn>
                    <DxColumn
                        :allowHeaderFiltering="false"
                        alignment="left"
                        data-field="thoiGianTruyenTaiDenBen"
                        caption="Thời gian truyền tải"
                        dataType="date"
                        format="HH:mm:ss dd/MM/yyyy"
                    ></DxColumn>
                </DxColumn>
                <template #truyen-tai-den-ben="{ data }">
                    <div
                        :style="`color:${
                            data.data.truyenTai_DenBen_ThanhCong == true
                                ? '#13B000'
                                : data.data.truyenTai_DenBen_ThanhCong == null
                                ? '#FB8C00'
                                : '#D10909'
                        }`"
                    >
                        {{
                            data.data.truyenTai_DenBen_ThanhCong == null
                                ? "Chưa truyền tải"
                                : data.data.truyenTai_DenBen_ThanhCong == true
                                ? "Truyền tải thành công"
                                : "Truyền tải gặp lỗi"
                        }}
                    </div>
                </template>
                <DxColumn
                    :allowHeaderFiltering="true"
                    :allowFiltering="false"
                    fixed-position="right"
                    width="244px"
                    alignment="left"
                    :fixed="true"
                    data-field="trangThai"
                    caption="Trạng thái"
                    cell-template="diff-cell-template"
                >
                </DxColumn>
                <template #diff-cell-template="{ data }">
                    <div :style="`color:${data.data.maMauTrangThai}`">
                        {{ data.data.trangThai }}
                    </div>
                </template>

                <DxColumn
                    :fixed="true"
                    fixed-position="right"
                    cssClass="column-button-hover"
                    data-field="Detail"
                    caption=""
                    width="0"
                    cell-template="buttons-cell"
                ></DxColumn>
                <template #buttons-cell="{ data }">
                    <div class="buttons">
                        <DxButton
                            id="mdi-eye"
                            icon="mdi mdi-eye"
                            hint="Xem chi tiết lệnh"
                            class="mx-1"
                            @click="XemChiTiet(data.data)"
                        ></DxButton>
                    </div>
                </template>
                <!-- <DxSummary>
                    <DxTotalItem
                        column="bienKiemSoat"
                        summary-type="count"
                        display-format=""
                        :show-in-group-footer="true"
                        :align-by-column="true"
                    />
                </DxSummary> -->
            </DxDataGrid>

            <DxList
                v-else
                :dataSource="DanhSachTraCuuLenhWithKey"
                height="calc(100vh - 188px)"
                page-load-mode="nextButton"
                :hoverStateEnabled="false"
                :focusStateEnabled="false"
                :activeStateEnabled="false"
                no-data-text="Không có dữ liệu!"
                class="list-lenh-style"
                :grouped="true"
                :collapsible-groups="true"
            >
                <template #item="{ data: item }">
                    <FrameItemVue :item="item" @XemBanTheHien="XemBanTheHien" />
                </template>
            </DxList>
        </div>
    </div>
    <DxPopup
        v-if="ifPopupShowImage"
        v-model:visible="PopupShowImage"
        :drag-enabled="false"
        :close-on-outside-click="true"
        :show-title="false"
        :position="position"
        width="488px"
        height="100%"
        :wrapper-attr="{ class: 'popup-no-padding' }"
        :animation="animationOptions"
        @hidden="onHiddenPopup('PopupShowImage')"
    >
        <div class="row" style="height: 100%">
            <div class="xs12" style="border-right: 1px solid #ddd">
                <div class="container" style="height: 100%">
                    <div class="row justify-space-between align-center mb-3">
                        <div class="font-16 font-medium">
                            Bản thể hiện lệnh vận chuyển
                        </div>
                        <div class="grow">
                            <DxButton
                                v-if="itemData && itemData.linkTaiXml"
                                icon="mdi mdi-download-outline"
                                text="XML"
                                type="default"
                                @click="taiFileXML(itemData)"
                            />
                            <DxButton
                                v-if="itemData && itemData.qrCode"
                                icon="mdi mdi-download-outline"
                                text="PDF"
                                class="ml-3"
                                type="default"
                                @click="taiFilePDF(itemData)"
                            />
                        </div>
                    </div>

                    <div class="ban-the-hien mb-5" style="height: calc(100% - 24px)">
                        <div class="frame-gallery" style="height: calc(100% - 24px)">
                            <div
                                class="row align-center justify-center"
                                style="height: 100%"
                                v-if="!itemData.qrCode"
                            >
                                Không có bản thể hiện
                            </div>

                            <embed
                                :src="itemData.qrCode"
                                width="100%"
                                height="100%"
                                v-else
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DxPopup>

    <DxPopup
        v-if="ifShowRecaptchaV2"
        v-model:visible="ShowRecaptchaV2"
        :drag-enabled="false"
        :close-on-outside-click="true"
        :show-title="false"
        width="auto"
        height="auto"
        @hidden="onHiddenPopup('ShowRecaptchaV2')"
    >
        <VueRecaptcha
            ref="recaptchaV2"
            :sitekey="SiteKey || null"
            size="normal"
            theme="light"
            hl="vi"
            :loading-timeout="loadingTimeout"
            @verify="recaptchaVerified"
            @expire="recaptchaExpired"
            @fail="recaptchaFailed"
            @error="recaptchaError"
        ></VueRecaptcha>
    </DxPopup>

    <DxPopup
        v-if="ifPopupChiTietLenh"
        v-model:visible="PopupChiTietLenh"
        :drag-enabled="false"
        :close-on-outside-click="true"
        :show-title="false"
        :position="position"
        :width="500"
        height="100vh"
        :wrapperAttr="{
            class: 'popup-no-padding',
        }"
        :animation="animationOptions"
    >
        <template #content>
            <DxScrollView width="100%" height="100%">
                <PopupChiTietLenhVue :ChiTietLenh="ChiTietLenh" />
            </DxScrollView>
        </template>
    </DxPopup>
</template>

<script>
import {
    DxValidationGroup,
    DxDateBox,
    DxButton,
    DxDataGrid,
    DxList,
    DxPopup,
    DxRadioGroup,
    DxTextBox,
    DxScrollView,
} from "devextreme-vue";
import {
    DxValidator,
    DxRequiredRule,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/validator";
import {
    DxFilterRow,
    DxPaging,
    DxScrolling,
    DxColumnFixing,
    DxColumn,
    DxSummary,
    DxTotalItem,
    DxHeaderFilter,
} from "devextreme-vue/data-grid";
import FrameItemVue from "./FrameItem.vue";
import VueRecaptcha from "vue3-recaptcha2";
import PopupChiTietLenhVue from "../../../components/Common/PopupChiTietLenh.vue";
const gridRefName = "DanhSachTraCuuLenh";
var date = new Date();
var SiteKey = SP[`FE_SiteKey`];
export default {
    components: {
        DxScrollView,
        DxList,
        DxValidator,
        DxValidationGroup,
        DxDateBox,
        DxRequiredRule,
        DxRangeRule,
        DxButton,
        DxDataGrid,
        DxList,
        DxFilterRow,
        DxPaging,
        DxScrolling,
        DxColumnFixing,
        DxColumn,
        DxSummary,
        DxTotalItem,
        DxHeaderFilter,
        DxPopup,
        DxTextBox,
        FrameItemVue,
        VueRecaptcha,
        DxRadioGroup,
        DxStringLengthRule,
        PopupChiTietLenhVue,
    },
    data() {
        return {
            SiteKey,
            MaxDate: this.$Helper.Common.getEndDay(
                new Date(this.$t("DefaultNumBer.MaxDate"))
            ),
            MinDate: this.$Helper.Common.getStartDay(
                new Date(this.$t("DefaultNumBer.MinDate"))
            ),
            ifShowRecaptchaV2: false,
            ShowRecaptchaV2: false,
            loadingTimeout: 30000,
            captchaToken: null,
            DanhSachKieuTraCuu: [
                { value: "BienKiemSoat", text: "Biển kiểm soát" },
                { value: "MaLenh", text: "Mã Lệnh" },
            ],
            gridRefName,
            PopupShowImage: false,
            ifPopupShowImage: false,
            ifPopupChiTietLenh: false,
            PopupChiTietLenh: false,
            IdLenh: null,
            closeButtonOptions: {
                text: "Đóng",
                onClick: () => {
                    this.hiddenPopup("PopupShowImage");
                },
            },
            tmpImg: null,
            animationOptions: {
                hide: {
                    type: "slide",
                    duration: 400,
                    from: {
                        position: { my: "right", at: "right", of: window },
                    },
                    to: { position: { my: "left", at: "right", of: window } },
                },
                show: {
                    type: "slide",
                    duration: 400,
                    from: { position: { my: "left", at: "right", of: window } },
                    to: { position: { my: "left", at: "right", of: window } },
                },
            },
            position: { my: "right", at: "right", of: window },
            itemData: {},
            model: {
                TrangThai: null,
                DateFrom: new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    0,
                    0,
                    0
                ),
                DateTo: new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    23,
                    59,
                    59
                ),
            },
        };
    },
    computed: {
        dataGrid() {
            return this.$refs[gridRefName].instance;
        },
        screenWidth() {
            return this.$store.state.ManHinh.screenWidth;
        },
        ThongTinTraCuu: {
            get() {
                return this.$store.state.TraCuuLenh.ThongTinTraCuu;
            },
            set(data) {
                this.$store.commit("TraCuuLenh/Set", {
                    key: "ThongTinTraCuu",
                    data: data,
                });
            },
        },
        DanhSachTraCuuLenh: {
            get() {
                return this.$store.state.TraCuuLenh.DanhSachTraCuuLenh;
            },
            set(data) {
                this.$store.commit("TraCuuLenh/Set", {
                    key: "DanhSachTraCuuLenh",
                    data: data,
                });
            },
        },
        ChiTietLenh: {
            get() {
                return this.$store.state.TraCuuLenh.ChiTietLenh;
            },
            set(data) {
                this.$store.commit("TraCuuLenh/Set", {
                    key: "ChiTietLenh",
                    data: data,
                });
            },
        },
        DanhSachTraCuuLenhWithKey() {
            let ObjDanhSachTraCuuLenhWithKey = {};
            let ArrDanhSachTraCuuLenhWithKey = [];
            this.DanhSachTraCuuLenh.forEach((e) => {
                if (!ObjDanhSachTraCuuLenhWithKey[e.tenDoanhNghiep]) {
                    ObjDanhSachTraCuuLenhWithKey[e.tenDoanhNghiep] = [];
                }
                ObjDanhSachTraCuuLenhWithKey[e.tenDoanhNghiep].push(e);
            });

            for (const item in ObjDanhSachTraCuuLenhWithKey) {
                ArrDanhSachTraCuuLenhWithKey.push({
                    key: item,
                    items: ObjDanhSachTraCuuLenhWithKey[item],
                });
            }
            return ArrDanhSachTraCuuLenhWithKey;
        },
    },
    methods: {
        onHiddenPopup(name) {
            this["if" + name] = false;
        },
        hiddenPopup(name) {
            this[name] = false;
        },
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        GetTextLaiXe(data) {
            return (data.danhSachLaiXe || []).map((e) => e.tenLaiXe).join(" | ");
        },
        headerFilterDataKyLenh(key) {
            return [
                {
                    text: "Đã ký",
                    value: [key, "=", true],
                },
                {
                    text: "Chưa ký",
                    value: [key, "=", false],
                },
            ];
        },
        headerFilterDataTruyenTai(key) {
            return [
                {
                    text: "Truyền tải thành công",
                    value: [key, "=", true],
                },
                {
                    text: "Chưa truyền tải",
                    value: [key, "=", false],
                },
            ];
        },
        async taiFilePDF(itemData) {
            if (itemData.qrCode) {
                // let result = await this.$Core.Api.LenhVanChuyen(itemData.qrCode)
                //     .Header("Attachment", true)
                //     .Config((c) => {
                //         c.responseType = "blob";
                //         c.validateStatus = () => true;
                //     })
                //     .Get();
                // const url = URL.createObjectURL(result.Data);
                let a = document.createElement("a");
                // a.href = "http://10.10.20.1:5262/api/FilePDF/downloadPDF";
                a.href =
                    itemData.qrCode +
                    `?isAttach=true&fileName={${itemData.bienKiemSoat}}_{${itemData.maLenh}}_{${itemData.maTuyen}}.pdf`;
                a.textContent = "Tada";
                a.download = `{${itemData.bienKiemSoat}}_{${itemData.maLenh}}_{${itemData.maTuyen}}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        },
        async taiFileXML(itemData) {
            if (itemData.linkTaiXml) {
                let result = await this.$Core.Api.LenhVanChuyen(
                    itemData.linkTaiXml
                ).Get();

                if (result.Data.status) {
                    const xmlData = result.Data.data;

                    const blob = new Blob([xmlData], { type: "application/xml" });

                    const url = URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `{${itemData.bienKiemSoat}}_{${itemData.maLenh}}_{${itemData.maTuyen}}.xml`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    URL.revokeObjectURL(url);
                }
            }
        },
        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
        onEditorPreparing(e) {
            this.$Helper.FilterRowWhenEnter(e);
        },
        XemBanTheHien(data) {
            this.itemData = data;
            if (this.screenWidth > 768) {
                this.showPopup("PopupShowImage");
            } else {
                this.ChiTietLenh = JSON.parse(JSON.stringify(data));
                console.log("🚀 ~ XemBanTheHien ~ this.ChiTietLenh:", this.ChiTietLenh);
                this.$router.push("/Xem-Ban-The-Hien-Lenh");
            }
        },
        TraCuu() {
            if (!this.ThongTinTraCuu.OptionTraCuu) {
                this.TraCuuLenhTheoIdLenh();
                return;
            }
            let validate = this.$refs.formValidation.instance.validate();
            if (!validate.isValid) {
                return this.$Helper.Common.autoFocusError(validate);
            }
            this.showPopup("ShowRecaptchaV2");
        },
        TraCuuLenhTheoIdLenh() {
            this.showPopup("ShowRecaptchaV2");
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
        },
        recaptchaFailed() {},
        recaptchaError(reason) {
            console.log("🚀 ~ recaptchaError ~ reason:", reason);
        },
        recaptchaVerified(token) {
            this.captchaToken = token;
            this.ShowRecaptchaV2 = false;
            if (!this.ThongTinTraCuu.OptionTraCuu) {
                this.ThucHienTraCuuLenhTheoIdLenh();
            } else {
                this.ThucHienTraCuuLenh();
            }
        },
        async ThucHienTraCuuLenhTheoIdLenh() {
            try {
                this.$startLoading;
                this.DanhSachTraCuuLenh = [];
                let result = await this.$Core.Api.LenhVanChuyen(
                    this.$t("urlTraCuuLenh.TraCuuThongTinLenhTheoId"),
                    {
                        idLenh: this.IdLenh,
                    }
                )
                    .Header("X-ReCaptcha-Token", this.captchaToken)
                    .Get();

                if (result.Data.status) {
                    this.DanhSachTraCuuLenh = result.Data.data || [];
                } else {
                    this.$Helper.ThongBaoPopup({
                        type: "Error",
                        message_title: "Tra cứu lệnh thất bại!",
                        message: result.Data.message,
                    });
                }
            } catch (error) {
                console.log("🚀 ~ ThucHienTraCuuLenh ~ error:", error);
            } finally {
                this.IdLenh = null;
                this.$stopLoading;
            }
        },
        async ThucHienTraCuuLenh() {
            try {
                this.$startLoading;
                this.DanhSachTraCuuLenh = [];
                let result = await this.$Core.Api.LenhVanChuyen(
                    this.$t("urlTraCuuLenh.TraCuuThongTinLenh"),
                    {
                        MaSoThue: this.ThongTinTraCuu.MaSoThue,
                        MaSoLenh:
                            this.ThongTinTraCuu.OptionTraCuu == "MaLenh"
                                ? this.ThongTinTraCuu.MaLenh
                                : null,
                        BienKiemSoat:
                            this.ThongTinTraCuu.OptionTraCuu == "BienKiemSoat"
                                ? this.ThongTinTraCuu.BienKiemSoat
                                : null,
                        NgayKhoiHanh:
                            this.ThongTinTraCuu.OptionTraCuu == "BienKiemSoat"
                                ? this.ThongTinTraCuu.NgayKhoiHanh
                                : null,
                    }
                )
                    .Header("X-ReCaptcha-Token", this.captchaToken)
                    .Get();

                if (result.Data.status) {
                    this.DanhSachTraCuuLenh = result.Data.data || [];
                } else {
                    this.$Helper.ThongBaoPopup({
                        type: "Error",
                        message_title: "Tra cứu lệnh thất bại!",
                        message: result.Data.message,
                    });
                }
            } catch (error) {
                console.log("🚀 ~ ThucHienTraCuuLenh ~ error:", error);
            } finally {
                this.$stopLoading;
            }
        },
        XemChiTiet(data) {
            this.ChiTietLenh = JSON.parse(JSON.stringify(data));
            this.showPopup("PopupChiTietLenh");
        },
    },
    mounted() {
        let query = this.$route.query;
        if (query.idLenh) {
            this.ThongTinTraCuu.OptionTraCuu = null;
            this.IdLenh = query.idLenh;
            this.TraCuuLenhTheoIdLenh();
        }
    },
};
</script>

<style scoped>
:deep(.list-lenh-style .dx-item.dx-list-item) {
    margin-bottom: 4px;
    padding: 6px;
}
:deep(.list-lenh-style .dx-list-group) {
    padding: 0 8px;
}
:deep(.list-lenh-style .dx-list-group-body) {
    /* max-height: calc(100vh - 36px - 36px - 48px - 44px - 40px); */
}
:deep(.list-lenh-style .dx-list-group-header) {
    color: #fb8c00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-direction: row-reverse;
    padding: 8px 8px 4px;
    border: 1px solid #dadce0;
    border-radius: 4px;
}
:deep(.list-lenh-style .dx-list-group:not(.dx-list-group-collapsed):not(:last-child)) {
    border: unset;
}
</style>
