<template>
    <div>
        <DxToolbar style="height: 56px" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <routerVue :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
        </DxToolbar>
        <div style="height: 100%">
            <DataTableVue :dataSrc="dataSrc" @getData="getData" />
        </div>
    </div>
</template>

<script>
import DataTableVue from "./components/DataTable.vue";
import { DxToolbar, DxItem } from "devextreme-vue/toolbar";
import routerVue from "@sonphat/common-v1/components/router.vue";
export default {
    layout: "application",
    components: {
        DataTableVue,
        DxToolbar,
        DxItem,
        routerVue,
    },
    data() {
        return {
            dataSrc: [],
            breadcrumbsItems: [
                {
                    id: "breadcrums_TraCuuLenh",
                    text: "Tra cứu lệnh điện tử",
                    disabled: true,
                    to: "",
                },
                // {
                //     id: "breadcrums_NhatTrinhXe",
                //     text: "Nhật trình xe",
                //     disabled: true,
                //     to: "",
                // },
            ],
        };
    },
    computed: {
        screenWidth() {
            return this.$store.state.ManHinh.screenWidth;
        },
    },
    methods: {
        async getData(TuNgay, DenNgay) {
            // let self = this;
            // let query = {
            //     TuNgay: this.$Helper.getStartDay(TuNgay),
            //     DenNgay: this.$Helper.getEndDay(DenNgay),
            // };
            // try {
            //     this.dataSrc = (
            //         await this.$store.dispatch("BaoCao/Get_NhatTrinhXe", {
            //             startLoading() {
            //                 self.$startLoading;
            //             },
            //             stopLoading() {
            //                 self.$stopLoading;
            //             },
            //             args: query,
            //             key: "BienSo",
            //         })
            //     ).Store;
            // } catch (ex) {
            //     console.error(ex);
            // }
        },
    },

    created() {
        this.getData(new Date(), new Date());
    },
};
</script>

<style scoped></style>
