<template>
    <div class="mx-4 mt-4">
        <div class="row justify-center font-20 font-bold mb-3">Thông tin chi tiết</div>

        <div class="row justify-center">
            <QrcodeVue
                v-if="ChiTietLenh.qrCode"
                :value="ChiTietLenh.qrCode"
                :size="244"
                :margin="4"
                level="L"
                style="
                    width: 244px !important;
                    height: 244px !important;
                    max-width: unset;
                "
            />
            <!-- <img
				v-if="ChiTietLenh.qrCode"
				:src="`https://chart.googleapis.com/chart?cht=qr&chs=244x244&choe=UTF-8&chld=L&chl=${ChiTietLenh.qrCode}`"
				style="
					width: 244px !important;
					height: 244px !important;
					max-width: unset;
				"
				alt=""
			/> -->
        </div>
        <div :class="classUnderQRCode" class="mt-2">
            {{ ChiTietLenh.maLenh }} ֎ {{ ChiTietLenh.bienKiemSoat }}
        </div>
        <div :class="classUnderQRCode" class="mb-3">
            {{ $Helper.formatDate(ChiTietLenh.hieuLucTuNgay) }} -
            {{ $Helper.formatDate(ChiTietLenh.hieuLucDenNgay) }}
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Trạng thái</div>
            <div
                :class="floatRightNormal"
                :style="`color: ${ChiTietLenh.maMauTrangThai}`"
            >
                {{ ChiTietLenh.trangThai }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Mã tuyến</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.maTuyen }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Bến đi</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.benDi }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Bến đến</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.benDen }}
            </div>
        </div>

        <div
            :class="rowSpaceBetween"
            v-for="(item, index) in ChiTietLenh.danhSachLaiXe"
            :key="index"
        >
            <div :class="floatLeftNormal">Lái xe {{ index + 1 }}</div>
            <div :class="floatRightLink">
                {{ item.tenLaiXe }} - {{ item.hangBangLai }}
            </div>
        </div>

        <div
            :class="rowSpaceBetween"
            v-if="
                ChiTietLenh.idTrangThai == $t('TrangThaiLenh.KhongThucHienHanhTrinh') ||
                ChiTietLenh.idTrangThai == $t('TrangThaiLenh.KhongHoanThanhHanhTrinh')
            "
        >
            <div :class="floatLeftNormal">
                {{
                    ChiTietLenh.idTrangThai == $t("TrangThaiLenh.KhongHoanThanhHanhTrinh")
                        ? "Thời gian dừng do xảy ra sự cố"
                        : ChiTietLenh.idTrangThai ==
                          $t("TrangThaiLenh.KhongThucHienHanhTrinh")
                        ? "Thời gian xác nhận từ chối lệnh"
                        : ""
                }}
            </div>
            <div :class="floatRightNormal">
                {{ $Helper.formatDate(ChiTietLenh.thoiGianDungHanhTrinh) }}
            </div>
        </div>

        <div
            :class="rowSpaceBetween"
            v-if="
                ChiTietLenh.idTrangThai == $t('TrangThaiLenh.KhongThucHienHanhTrinh') ||
                ChiTietLenh.idTrangThai == $t('TrangThaiLenh.KhongHoanThanhHanhTrinh')
            "
        >
            <div :class="floatLeftNormal">
                {{
                    ChiTietLenh.idTrangThai == $t("TrangThaiLenh.KhongHoanThanhHanhTrinh")
                        ? "Người dừng hành trình"
                        : ChiTietLenh.idTrangThai ==
                          $t("TrangThaiLenh.KhongThucHienHanhTrinh")
                        ? "Lái xe xác nhận"
                        : ""
                }}
            </div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.laiXeDungHanhTrinh }}
            </div>
        </div>

        <div
            :class="rowSpaceBetween"
            v-if="ChiTietLenh.idTrangThai == $t('TrangThaiLenh.Huy')"
        >
            <div :class="floatLeftNormal">Lý do</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.lyDoHuy || null }}
            </div>
        </div>

        <div
            :class="rowSpaceBetween"
            v-if="
                ChiTietLenh.idTrangThai == $t('TrangThaiLenh.KhongHoanThanhHanhTrinh') ||
                ChiTietLenh.idTrangThai == $t('TrangThaiLenh.KhongHoanThanhHanhTrinh') ||
                ChiTietLenh.idTrangThai == $t('TrangThaiLenh.LaiXeKhongTiepNhan')
            "
        >
            <div :class="floatLeftNormal">Lý do</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.lyDoDungHanhTrinh || null }}
            </div>
        </div>

        <div v-if="ChiTietLenh?.xenghI_BienKiemSoat">
            <div :class="rowSpaceBetween">
                <div :class="floatLeftTitle">Thông tin xe nghỉ</div>
            </div>

            <div :class="rowSpaceBetween">
                <div :class="floatLeftNormal">Biển kiểm soát</div>
                <div :class="floatRightNormal">
                    {{ ChiTietLenh?.xenghI_BienKiemSoat }}
                </div>
            </div>
            <div :class="rowSpaceBetween">
                <div :class="floatLeftNormal">Số ghế | Số giường</div>
                <div :class="floatRightNormal">
                    {{ ChiTietLenh?.xenghI_SoGhe }} | {{ ChiTietLenh?.xenghI_SoGiuong }}
                </div>
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftTitle">Thông tin giấy tờ xe</div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Hạn phù hiệu tuyến</div>
            <div :class="floatRightLink">
                {{ $Helper.formatDate(ChiTietLenh.hanPhuHieuTuyen) }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Hạn đăng kiểm</div>
            <div :class="floatRightLink">
                {{ $Helper.formatDate(ChiTietLenh.hanDangKiem) }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Hạn bảo hiểm</div>
            <div :class="floatRightLink">
                {{ $Helper.formatDate(ChiTietLenh.hanBaoHiem) }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftTitle">Thông tin DNVT</div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Tên DNVT</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.tenDoanhNghiep }}
            </div>
        </div>
        <div :class="rowSpaceBetween" v-if="ChiTietLenh.tenNhaXe">
            <div :class="floatLeftNormal">Tên nhà xe</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.tenNhaXe }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftTitle">Thông tin cấp lệnh</div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Người tạo lệnh</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.nguoiTaoLenh }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Thời gian tạo lệnh</div>
            <div :class="floatRightNormal">
                {{ $Helper.formatDateTime(ChiTietLenh.ngayTaoLenh) }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Người cấp lệnh</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.nguoiKyLenh }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Thời gian cấp lệnh</div>
            <div :class="floatRightNormal">
                {{ $Helper.formatDateTime(ChiTietLenh.thoiGianKyLenh) }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftTitle">Thông tin chuyến đi</div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Số ghế | Số giường</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.soGhe }} | {{ ChiTietLenh.soGiuong }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Hình thức chạy</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.hinhThucChay }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Hành trình tuyến</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.hanhTrinhChay }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Nhân viên phục vụ xe</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.nhanVienPhucVuTrenXe }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftTitle">Thông tin thực tế</div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Giờ xuất bến</div>
            <div :class="floatRightNormal">
                {{ $Helper.formatDateTime(ChiTietLenh.gioXuatBen) }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Số khách xuất bến</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.soKhachXuatBen }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Giờ đến bến</div>
            <div :class="floatRightNormal">
                {{ $Helper.formatDateTime(ChiTietLenh.gioDenBen) }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Số khách đến bến</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.soKhachDenBen }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftTitle">Thông tin ký lệnh</div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Thủ trưởng đơn vị</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.thuTruongDonViDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>

        <div
            :class="rowSpaceBetween"
            v-for="(item, index) in ChiTietLenh.danhSachLaiXe"
            :key="index"
        >
            <div :class="floatLeftNormal">Lái xe {{ index + 1 }}</div>
            <div :class="floatRightNormal">
                {{ item.daKyLenh ? "Đã xác nhận" : "Chưa xác nhận" }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Bến đi</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.benDiDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>
        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Truyền tải xuất bến</div>
            <div :class="floatRightNormal">
                {{
                    ChiTietLenh.truyenTai_XuatBen_ThanhCong == null
                        ? "Chưa truyền tải"
                        : ChiTietLenh.truyenTai_XuatBen_ThanhCong == true
                        ? "Truyền tải thành công"
                        : "Truyền tải gặp lỗi"
                }}
            </div>
        </div>
        <div
            :class="rowSpaceBetween"
            style="margin-bottom: 80px"
            v-if="ChiTietLenh.truyenTai_XuatBen_ThanhCong"
        >
            <div :class="floatLeftNormal">Thời gian truyền tải xuất bến</div>
            <div :class="floatRightNormal">
                {{ $Helper.formatDateTime(ChiTietLenh.thoiGianTruyenTaiXuatBen) }}
            </div>
        </div>

        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Bến đến</div>
            <div :class="floatRightNormal">
                {{ ChiTietLenh.benDenDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>
        <div :class="rowSpaceBetween">
            <div :class="floatLeftNormal">Truyền tải đến bến</div>
            <div :class="floatRightNormal">
                {{
                    ChiTietLenh.truyenTai_DenBen_ThanhCong == null
                        ? "Chưa truyền tải"
                        : ChiTietLenh.truyenTai_DenBen_ThanhCong == true
                        ? "Truyền tải thành công"
                        : "Truyền tải gặp lỗi"
                }}
            </div>
        </div>
        <div :class="rowSpaceBetween" v-if="ChiTietLenh.truyenTai_DenBen_ThanhCong">
            <div :class="floatLeftNormal">Thời gian truyền tải đến bến</div>
            <div :class="floatRightNormal">
                {{ $Helper.formatDateTime(ChiTietLenh.thoiGianTruyenTaiDenBen) }}
            </div>
        </div>

        <div
            :class="rowSpaceBetween"
            :style="!ChiTietLenh.truyenTai_XeBuyt_ThanhCong ? `margin-bottom: 80px` : ``"
        >
            <div :class="floatLeftNormal">Truyền tải xe buýt</div>
            <div :class="floatRightNormal">
                {{
                    ChiTietLenh.truyenTai_XeBuyt_ThanhCong == null
                        ? "Chưa truyền tải"
                        : ChiTietLenh.truyenTai_XeBuyt_ThanhCong == true
                        ? "Truyền tải thành công"
                        : "Truyền tải gặp lỗi"
                }}
            </div>
        </div>
        <div
            :class="rowSpaceBetween"
            style="margin-bottom: 80px"
            v-if="ChiTietLenh.truyenTai_XeBuyt_ThanhCong"
        >
            <div :class="floatLeftNormal">Thời gian truyền tải xe buýt</div>
            <div :class="floatRightNormal">
                {{ $Helper.formatDateTime(ChiTietLenh.thoiGianTruyenTaiXeBuyt) }}
            </div>
        </div>

        <div :class="rowSpaceBetween" class="row-button">
            <DxButton
                v-if="ChiTietLenh.qrCode"
                id="3"
                text="XEM FILE"
                type="default"
                styling-mode="contained"
                icon="mdi mdi-file-pdf-box"
                @click="XemBanTheHien"
            />
            <!-- ChiTietLenh.idTrangThai != parseInt($t('TrangThaiLenh.LenhNhap')) && -->
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
import QrcodeVue from "qrcode.vue";
export default {
    components: { DxButton, QrcodeVue },
    props: {
        ChiTietLenh: { type: Object, default: {} },
    },
    data() {
        return {
            classUnderQRCode: "row justify-center mt-1 font-medium font-16 font-italic",
            floatLeftNormal: "xs5 ml-2  font-16 align-center text-no-wrap",
            floatLeftTitle: "xs12 mt-2 font-16 font-medium color-000",
            floatRightNormal:
                "xs7 font-medium font-16 mr-2 text-xs-right align-center text-no-wrap",
            floatRightLink:
                "xs7 font-medium font-16 mr-2 text-xs-right text-link align-center",
            rowSpaceBetween: "row justify-space-between align-center border-bottom mt-2",
        };
    },
    watch: {},
    methods: {
        XemBanTheHien() {
            this.$Helper.BrowserViewLink(
                this.ChiTietLenh.qrCode,
                `Lệnh điện tử ${this.ChiTietLenh.maLenh}.pdf`,
                this.ChiTietLenh
            );
        },
    },
    created() {},
};
</script>

<style scoped>
.row-button {
    position: fixed;
    bottom: 0;
    width: calc(100% - 48px);
    background: #fff;
    padding-top: 8px;
    padding-bottom: 24px;
}
.text-no-wrap {
    word-break: break-word;
}
.border-bottom {
    border-bottom: 1px dashed #dadce0;
}
</style>
